import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from './firebase';
import { Layout } from "./components/dashboard/layout";
import { Box, Typography, TextField, Button, List, ListItem, ListItemText, Grid, Paper } from '@mui/material';

const TemporaryIDReports = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reports, setReports] = useState([]);
  const [stats, setStats] = useState({ total: 0, returned: 0, pending: 0 });

  const fetchReports = async () => {
    if (!startDate || !endDate) return;
    
    try {
      const q = query(
        collection(db, 'temp_ids'),
        where("issue_time", ">=", new Date(startDate).toISOString()),
        where("issue_time", "<=", new Date(endDate).toISOString()),
        orderBy("issue_time", "desc")
      );
      
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setReports(data);
      
      const total = data.length;
      const returned = data.filter(id => id.returned).length;
      const pending = total - returned;
      setStats({ total, returned, pending });
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  return (
    <Layout>
      <Box sx={{ p: 4, maxWidth: '80%', width: '80%', margin: 'auto', textAlign: 'center', backgroundColor: '#f9f9f9', borderRadius: 3, boxShadow: 3 }}>
        <Typography variant="h4" gutterBottom>Temporary ID Reports</Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 3 }}>
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <TextField
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={fetchReports}>
            Fetch Reports
          </Button>
        </Box>

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={4}>
            <Paper sx={{ p: 2, textAlign: 'center', backgroundColor: '#e3f2fd' }}>
              <Typography variant="h6">Total Issued</Typography>
              <Typography variant="h5">{stats.total}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper sx={{ p: 2, textAlign: 'center', backgroundColor: '#c8e6c9' }}>
              <Typography variant="h6">Returned</Typography>
              <Typography variant="h5">{stats.returned}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper sx={{ p: 2, textAlign: 'center', backgroundColor: '#ffccbc' }}>
              <Typography variant="h6">Pending Returns</Typography>
              <Typography variant="h5">{stats.pending}</Typography>
            </Paper>
          </Grid>
        </Grid>

        <List>
          {reports.length > 0 ? reports.map((id) => (
            <ListItem key={id.id} sx={{ backgroundColor: '#fff', p: 2, mb: 1, borderRadius: 2, boxShadow: 1 }}>
              <ListItemText primary={`${id.userName} (${id.userId}) - Temp ID: ${id.tempIDNumber}`} secondary={`Issued: ${new Date(id.issue_time).toLocaleString()} | Returned: ${id.returned ? new Date(id.return_time).toLocaleString() : 'Pending'}`} />
            </ListItem>
          )) : <Typography variant="body1" sx={{ mt: 2, color: 'gray' }}>No records found.</Typography>}
        </List>
      </Box>
    </Layout>
  );
};

export default TemporaryIDReports;