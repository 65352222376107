import React, { useEffect, useState } from "react";
import { Layout } from "../../components/dashboard/layout";
import { DataGrid } from "@mui/x-data-grid";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Ensure this imports the initialized Firestore instance
import { useNavigate } from "react-router-dom";
import { TextField, Box } from "@mui/material";
import { doc, deleteDoc, query, where } from 'firebase/firestore';
import { Typography } from "@mui/material";

const Datagrid = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [gradeFilter, setGradeFilter] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersData = querySnapshot.docs.map((doc, index) => ({ 
          uniqueId: index + 1,
          id: doc.id,
          ...doc.data()
        }));        
        setItems(usersData);
        setFilteredItems(usersData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filtered = items.filter(item => {
      const matchesSearch =
        (typeof item.name === 'string' && item.name.toLowerCase().includes(searchQuery)) ||
        (typeof item.userId === 'string' && item.userId.toLowerCase().includes(searchQuery)) ||
        (typeof item.role === 'string' && item.role.toLowerCase().includes(searchQuery)) ||
        (typeof item.rfid === 'string' && item.rfid.toLowerCase().includes(searchQuery));
  
        const matchesGrade = gradeFilter !== ''
        ? item.grade_level === Number(gradeFilter)
        : true;
  
      return matchesSearch && matchesGrade;
    });
  
    setFilteredItems(filtered);
  }, [searchQuery, gradeFilter, items]);
  

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = items.filter(item => 
      (typeof item.name === 'string' && item.name.toLowerCase().includes(query)) ||
      (typeof item.userId === 'string' && item.userId.toLowerCase().includes(query)) ||
      (typeof item.role === 'string' && item.role.toLowerCase().includes(query)) ||
      (typeof item.rfid === 'string' && item.rfid.toLowerCase().includes(query))
    );
    setFilteredItems(filtered);
  };

  const handleDelete = async (userIdToDelete) => {
    const confirm = window.confirm(
      "Before deleting this user, please ensure they have been marked as inactive in PowerSchool to prevent them from reappearing in this system.\n\nAre you sure you want to proceed with deleting this user?"
    );
    if (!confirm) return;    
  
    try {
      // Get the doc with the matching userId
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where("userId", "==", userIdToDelete));
      const snapshot = await getDocs(q);
  
      snapshot.forEach(async (docSnapshot) => {
        await deleteDoc(doc(db, 'users', docSnapshot.id));
      });
  
      // Remove from local state
      const updatedItems = items.filter(item => item.userId !== userIdToDelete);
      setItems(updatedItems);
      setFilteredItems(updatedItems);
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Failed to delete user. Please try again.");
    }
  };

const UserAvatar = ({ id, name }) => {
  const [imageError, setImageError] = useState(false);

  const initials = (name || "")
    .split(" ")
    .map(word => word[0])
    .join("")
    .slice(0, 2)
    .toUpperCase();

  const imageUrl = `https://raptornet.aischennai.org/screening-manager/api/images/${id}.jpg`;

  return !imageError ? (
    <img
      src={imageUrl}
      alt={name}
      onError={() => setImageError(true)}
      style={{
        width: 50,
        height: 50,
        borderRadius: '50%',
        objectFit: 'cover',
      }}
    />
  ) : (
    <div
      style={{
        width: 50,
        height: 50,
        borderRadius: '50%',
        backgroundColor: '#ccc',
        color: '#333',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        fontSize: '14px',
      }}
    >
      {initials}
    </div>
  );
};


  const handleRowClick = (params) => {
    navigate(`/edit-user/${params.row.userId}`);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'grade_level', headerName: 'Grade', width: 250 },
    { field: 'role', headerName: 'Type', width: 250 },
    {
      field: 'image',
      headerName: 'Image',
      width: 100,
      renderCell: (params) => (
        <UserAvatar id={params.row.id} name={params.row.name} />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      renderCell: (params) => (
        <button
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click navigation
            handleDelete(params.row.userId);
          }}
          style={{
            backgroundColor: '#e53935',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            padding: '6px 12px',
            cursor: 'pointer',
          }}
        >
          Delete
        </button>
      )
    }
  ];

  return (
    <Layout>
      <Typography variant="h4" component="h1" gutterBottom style={{padding: `1%`, borderBottom: `1px solid #ccc`, fontSize: `20px`, fontWeight: `bold`}}>
        User Management
      </Typography>
      <Box display="flex" gap={2} alignItems="center" mb={2} style={{marginTop: `20px`, padding: `1%`}}>
        {/* Search */}
        <TextField
          label="Search anything"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          size="small"
          sx={{ width: 300 }}
        />

        {/* Grade Filter */}
        <TextField
          label=""
          variant="outlined"
          select
          value={gradeFilter}
          onChange={(e) => setGradeFilter(e.target.value)}
          size="small"
          sx={{ width: 200 }}
          SelectProps={{ native: true }}
        >
          <option value="">Select Grade</option>
          <option value="-1">EY</option>
          <option value="0">KG</option>
          {[...Array(11)].map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </TextField>
      </Box>

      <div style={{ height: '900px', width: '90%', marginTop: `0px`, padding: `1%` }}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <DataGrid
            rows={filteredItems}
            columns={columns}
            pageSize={5}
            checkboxSelection
            getRowId={(row) => row.id} // Use uniqueId as the row identifier
            onRowClick={handleRowClick}
          />
        )}
      </div>
    </Layout>
  );
};

export default Datagrid;
