import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, doc, updateDoc, query, where, orderBy } from 'firebase/firestore';
import { db } from './firebase';
import { Layout } from "./components/dashboard/layout";
import { Box, Typography, Alert, TextField, Autocomplete, Button, ToggleButton, ToggleButtonGroup, Card, CardContent } from '@mui/material';
import { CheckCircle, HourglassEmpty } from '@mui/icons-material';
import emailjs from "emailjs-com";

const TemporaryIDManagement = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [reason, setReason] = useState(''); // Default empty to force selection
  const [alert, setAlert] = useState(null);
  const [issuedToday, setIssuedToday] = useState([]);
  const [pendingReturns, setPendingReturns] = useState([]);
  const [tempIDNumber, setTempIDNumber] = useState('');
  const [userType, setUserType] = useState(null); // Track user type selection (null initially)
  const [phoneNumber, setPhoneNumber] = useState(''); // Store phone number
  const [userMap, setUserMap] = useState({}); // Map to store user data including grade

  useEffect(() => {
    fetchUsers();
    fetchTemporaryIDs();
}, []); // Fetch temp IDs only after userMap is populated

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchTemporaryIDs = async () => {
    try {
      const today = new Date().toISOString().split('T')[0];
      const qToday = query(collection(db, 'temp_ids'), where("issue_time", ">=", today), orderBy("issue_time", "desc"));
      const qPending = query(collection(db, 'temp_ids'), where("returned", "==", false), orderBy("issue_time", "desc"));
      
      const [todaySnapshot, pendingSnapshot] = await Promise.all([
        getDocs(qToday),
        getDocs(qPending)
      ]);
      
      const todayData = todaySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const pendingData = pendingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      console.log("Fetched Issued Today Data:", todayData);
      console.log("Fetched Pending Returns Data:", pendingData);

      setIssuedToday(todayData);
      setPendingReturns(pendingData);
    } catch (error) {
      console.error("Error fetching temporary IDs:", error);
    }
  };

  const issueTemporaryID = async () => {
      if (!selectedUser || !tempIDNumber.trim() || !reason) {
        setAlert({ severity: 'error', message: 'Please select a user, enter a temp ID number, and select a reason.' });
        return;
      }
      if (userType === 'parentStaff' && !phoneNumber.trim()) {
        setAlert({ severity: 'error', message: 'Please enter a phone number for Parent/Staff.' });
        return;
      }

      try {
        const selectedUserData = users.find(user => user.id === selectedUser.id);

        await addDoc(collection(db, 'temp_ids'), {
          userId: selectedUser.userId,
          userName: selectedUser.name,
          userType: selectedUser.role,
          tempIDNumber,
          reason,
          grade: selectedUserData?.grade_level || 'N/A',
          phoneNumber: userType === 'parentStaff' ? phoneNumber : null,
          issue_time: new Date().toISOString(),
          return_time: null,
          returned: false,
        });

        setAlert({ severity: 'success', message: 'Temporary ID issued successfully.' });

        if (selectedUser.role === "student") {

          // ✅ Send Email via EmailJS
          const emailParams = {
            studentname: selectedUser.name,
            studentemail: selectedUserData?.email, // Make sure email exists in users collection
            tempid: tempIDNumber,
            reason: reason,
            issuetime: new Date().toLocaleString(),
          };

          emailjs.send(
            "service_rhxygd6",    // Replace with your EmailJS Service ID
            "template_nctvbww",   // Replace with your EmailJS Template ID
            emailParams,
            "hOhXG527gJZ-pCztN"        // Replace with your EmailJS User ID
          ).then(response => {
              console.log("Email Sent Successfully:", response.status, response.text);
          }).catch(error => {
              console.error("Error sending email:", error);
          });
        }

        fetchTemporaryIDs();
        setTempIDNumber('');
        setPhoneNumber('');
        setReason('');
      } catch (error) {
        console.error("Error issuing temporary ID:", error);
        setAlert({ severity: 'error', message: 'Failed to issue temporary ID.' });
      }
  };

  const returnTemporaryID = async (id) => {
    try {
      await updateDoc(doc(db, 'temp_ids', id), { returned: true, return_time: new Date().toISOString() });
      setAlert({ severity: 'success', message: 'Temporary ID marked as returned.' });
      fetchTemporaryIDs();
    } catch (error) {
      console.error("Error returning temporary ID:", error);
      setAlert({ severity: 'error', message: 'Failed to return temporary ID.' });
    }
  };

  return (
    <Layout sx={{marginTop: '20px'}}>
      <Box sx={{ p: 4, maxWidth: '80%', width: '80%', margin: 'auto', textAlign: 'center', backgroundColor: '#f9f9f9', borderRadius: 3, boxShadow: 3 }}>
        <Typography variant="h4" gutterBottom>Temporary ID Management</Typography>
        {alert && <Alert severity={alert.severity} sx={{ mb: 2 }}>{alert.message}</Alert>}

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
          <Button variant={userType === 'student' ? "contained" : "outlined"} onClick={() => setUserType('student')}>Student</Button>
          <Button variant={userType === 'parentStaff' ? "contained" : "outlined"} onClick={() => setUserType('parentStaff')}>Parent/Staff</Button>
        </Box>

        {userType && (
          <>
            <Autocomplete
              options={users}
              getOptionLabel={(option) => `${option.userId} - ${option.name}`}
              onChange={(event, newValue) => setSelectedUser(newValue)}
              renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" fullWidth sx={{ mb: 2 }} />}
            />

            {userType === 'parentStaff' && (
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            )}

            <TextField
              label="Temporary ID Number"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              value={tempIDNumber}
              onChange={(e) => setTempIDNumber(e.target.value)}
            />

            <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>Select the reason for not bringing the ID:</Typography>
            <ToggleButtonGroup
              value={reason}
              exclusive
              onChange={(event, newValue) => setReason(newValue)}
              sx={{ mb: 2 }}
            >
            <ToggleButton value="Forgot"
              sx={{
                fontWeight: 'bold',
                fontSize: '1rem',
                paddingX: 3,
                paddingY: 1.2,
                borderRadius: 2,
                '&.Mui-selected': {
                  backgroundColor: '#2f3192',
                  color: '#fff',
                  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
                  '&:hover': {
                    backgroundColor: '#115293',
                  },
                }
              }}
            >
              Forgot
            </ToggleButton>

            <ToggleButton value="Lost"
              sx={{
                fontWeight: 'bold',
                fontSize: '1rem',
                paddingX: 3,
                paddingY: 1.2,
                borderRadius: 2,
                '&.Mui-selected': {
                  backgroundColor: '#2f3192',
                  color: '#fff',
                  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
                  '&:hover': {
                    backgroundColor: '#115293',
                  },
                }
              }}
            >
              Lost
            </ToggleButton>
          </ToggleButtonGroup>


            <Button variant="contained" color="primary" sx={{ mt: 2, width: '100%' }} onClick={issueTemporaryID}>
              Submit
            </Button>
          </>
        )}

<Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, mb: 3 }} style={{marginTop: `40px`}}>
  {/* Issued Today Card */}
  <Card sx={{ minWidth: 200, backgroundColor: '#e3f2fd', boxShadow: 3 }}>
    <CardContent>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1565c0', mb: 1 }}>
        Issued Today
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        {issuedToday.length}
      </Typography>
    </CardContent>
  </Card>

  {/* Pending Returns Card */}
  <Card sx={{ minWidth: 200, backgroundColor: '#ffebee', boxShadow: 3 }}>
    <CardContent>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#c62828', mb: 1 }}>
        Pending Returns
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        {pendingReturns.length}
      </Typography>
    </CardContent>
  </Card>
</Box>


        <Typography variant="h5" sx={{ mt: 4 }} style={{marginBottom: `20px`}}>Issued Temporary IDs Today</Typography>
        {issuedToday.map((id) => (
          <Box key={id.id} sx={{ backgroundColor: '#fff', p: 2, mb: 1, borderRadius: 2, boxShadow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Typography>{id.userName} ({id.userId}) - Temp ID: {id.tempIDNumber}</Typography>
              {id.userType === 'student' && (
                <Typography variant="caption" sx={{ float: 'left',fontWeight: 'bold' }}>Grade: {id.grade}</Typography>
              )}
              {id.userType !== 'student' && id.phoneNumber && (
                <Typography variant="caption" sx={{ float: 'left',fontWeight: 'bold' }}>Phone: {id.phoneNumber}</Typography>
              )}
              <Typography variant="caption" sx={{ marginLeft: '10px', float: 'left', fontWeight: 'bold', display: 'block' }}>Issued: {new Date(id.issue_time).toLocaleString()}</Typography>
            </Box>
            {id.returned ? <CheckCircle color="success" /> : <HourglassEmpty color="warning" />}
          </Box>
        ))}

        <Typography variant="h5" sx={{ mt: 4 }} style={{marginBottom: `20px`}}>Pending Returns</Typography>
        {pendingReturns.map((id) => (
          <Box key={id.id} sx={{ backgroundColor: '#fff', p: 2, mb: 1, borderRadius: 2, boxShadow: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography>{id.userName} ({id.userId}) - Temp ID: {id.tempIDNumber}</Typography>
              {id.userType === 'student' && (
                <Typography variant="caption" sx={{ float: 'left', fontWeight: 'bold' }}>Grade: {id.grade}</Typography>
              )}
              {id.userType !== 'student' && id.phoneNumber && (
                <Typography variant="caption" sx={{ marginLeft: '10px', float: 'left', fontWeight: 'bold' }}>Phone: {id.phoneNumber}</Typography>
              )}
              <Typography variant="caption" sx={{ marginLeft: '10px', float: 'left', fontWeight: 'bold', display: 'block' }}>Issued: {new Date(id.issue_time).toLocaleString()}</Typography>
              {id.returned && (
                <Typography variant="caption" sx={{ fontWeight: 'bold', display: 'block' }}>Returned: {new Date(id.return_time).toLocaleString()}</Typography>
              )}
            </Box>
            <Button variant="contained" color="secondary" onClick={() => returnTemporaryID(id.id)}>
              Mark as Returned
            </Button>
          </Box>
        ))}
      </Box>
    </Layout>
  );
};

export default TemporaryIDManagement;
