import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import { Layout } from "./components/dashboard/layout";
import { Box, TextField, Typography, Button } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Reports = () => {
  // Initialize searchDate with yesterday's date to prevent null errors
  const getYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  };

  const [logs, setLogs] = useState([]);
  const [reportLogs, setReportLogs] = useState([]);
  const [searchDate, setSearchDate] = useState(getYesterday()); // Avoid null values
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Function to format dates safely
  const formatDate = (date) => {
    if (!date) return ''; // Prevent errors if date is null
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const fetchData = async (date) => {
    if (!date) return; // Prevent fetching if date is null

    try {
      const formattedDate = formatDate(date);
      console.log(`Fetching data for the date: ${formattedDate}`);

      const logsQuery = query(
        collection(db, 'logs'),
        where('datetime', '>=', formattedDate),
        where('datetime', '<', `${formattedDate}T23:59:59`)
      );

      const logsSnapshot = await getDocs(logsQuery);
      const logsData = logsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      console.log('Fetched logs:', logsData);

      setLogs(logsData);
      setReportLogs(logsData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  // Fetch logs for the previous day when the component mounts
  useEffect(() => {
    fetchData(searchDate);
  }, []); // Runs once on mount

  const handleSearch = async () => {
    setIsLoading(true);

    if (!searchDate) return;

    const formattedSearchDate = formatDate(searchDate);
    console.log('Searching logs for:', formattedSearchDate, searchQuery);

    const filteredLogs = logs.filter(log =>
      (log.id && log.id.toLowerCase().includes(searchQuery.toLowerCase())) &&
      (log.datetime && log.datetime.includes(formattedSearchDate))
    );

    console.log('Filtered logs:', filteredLogs);
    setReportLogs(filteredLogs);
    setIsLoading(false);
  };

  const handleSearchQuery = (event) => {
    setSearchQuery(event.target.value);
  };

  // Generate last 15 days dynamically
  const getLast15Days = () => {
    return Array.from({ length: 15 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - i);
      return date;
    });
  };

  return (
    <Layout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>All Reports</Typography>

        {/* Buttons for the last 15 days */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3 }}>
          {getLast15Days().map((date, index) => (
            <Button
              key={index}
              variant={formatDate(date) === formatDate(searchDate) ? "contained" : "outlined"}
              onClick={() => {
                setSearchDate(date);
                fetchData(date);
              }}
            >
              {formatDate(date)}
            </Button>
          ))}
        </Box>

        {/* Search Box */}
        <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
          <DatePicker
            selected={searchDate}
            onChange={(date) => {
              setSearchDate(date);
              fetchData(date);
            }}
            dateFormat="yyyy-MM-dd"
            customInput={<TextField variant="outlined" label="Select Date" />}
          />
        </Box>

        {/* Table to display log data */}
        <Box sx={{ mt: 3 }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Date/Time</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>ID</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Mode</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Type</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Image</th>
              </tr>
            </thead>
            <tbody>
              {reportLogs.length === 0 ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: 'center', padding: '20px' }}>No logs found for the selected date.</td>
                </tr>
              ) : (
                reportLogs.map((log, index) => (
                  <tr key={index}>
                    <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{log.datetime}</td>
                    <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{log.id}</td>
                    <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{log.mode}</td>
                    <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{log.type}</td>
                    <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                      <img src={`https://raptornet.aischennai.org/screening-manager/api/images/${log.id}.jpg`} alt={log.id} width="50" height="50" />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Box>
      </Box>
    </Layout>
  );
};

export default Reports;
