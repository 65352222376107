import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Unstable_Grid2 as Grid,
  Stack,
  Alert,
  Paper,
  Typography,
  Chip,
  Avatar,
  Menu,
  MenuItem,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as dayjs from "dayjs";

import { Layout } from "../../components/dashboard/layout";
import { useParams, useNavigate } from "react-router-dom";
import { StackLayout } from "src/components/dashboard/stackLayout";
import moment from "moment";
import CalendarIcon from "@heroicons/react/24/solid/CalendarIcon";

import Webcam from "react-webcam";
import { CheckCircleOutline } from "@mui/icons-material";

import axios from "axios";
import keys from '../../constants.js';
import loaderIcon from '../../asserts/loader-ajax.gif';

import { DataGrid } from '@mui/x-data-grid';

import UserCard from './UserCard';


const AttestationForm = () => {

    const { id } = useParams();

    const [users, setUsers] = useState("");

    const navigate = useNavigate();

    function getDetails() {
        const url = keys.APIPATH + '/getVisitorDetails.php';
        const requestOptions = {
          method: 'GET',
          mode: 'no-cors',
          headers: { 'Content-Type': 'application/json' },
        };
    
        axios.post(url, {id: id})
          .then(response => {
            setUsers(response.data[0].name);
        });
    }

    function handleCardClick()
    {
        const url = keys.APIPATH + '/updateAttestation.php';
        const requestOptions = {
          method: 'GET',
          mode: 'no-cors',
          headers: { 'Content-Type': 'application/json' },
        };
    
        axios.post(url, {id: id})
          .then(response => {
            alert("Thank You!");
            navigate('/self-attestation');
        });
    }

    useEffect(() => {
        getDetails();
    }, []);

    return (
        <Layout>
            <Box sx={{ p: 4 }}>
                <div style={{ width: '100%', marginTop: '20px' }}>
                    <Typography variant="h5" gutterBottom style={{ color: `#a82e2e`, fontWeight: `bold` }}>
                        Visitor Self-Attestation - {users}
                    </Typography>
                    <div className="card">
                        <div className="card-body">
                            <p style={{ borderBottom: `2px solid #d1eaff`, paddingBottom: `10px` }}>
                                <b>Child safeguarding is everyone’s responsibility. AISC is committed to providing a safe environment for students, staff, parents, and visitors. Together, we create a safe and secure environment.</b>
                            </p>
                            <p><b>Please read the following carefully and sign where indicated.</b></p>
                            <p>By entering American International School Chennai (AISC),</p>
                            <p><b>I WILL:</b></p>
                            <ul className="instructions">
                                <li>Enter the campus on essential business and will stay only as long as necessary.</li>
                                <li>Maintain physical boundaries and avoid covert or overt sexual or inappropriate behaviors.</li>
                                <li>Remain in the designated spaces allotted for my event/purpose on campus and will not explore.</li>
                                <li>Only use designated adult bathrooms.</li>
                                <li>Cooperate with AISC Staff and AISC Security during emergency situations. Follow all directives given by AISC Staff and AISC Security.</li>
                                <li>Always wear the AISC-issued ID badge while on campus.</li>
                            </ul>
                            <p><b>I WILL NOT:</b></p>
                            <ul className="instructions">
                                <li>Engage with students in a capacity outside of my professional role.</li>
                                <li>Take pictures/videos of students who are not my own.</li>
                                <li>Ask for any students’ contact information or social media accounts and I will not share my personal contact information or social media with students.</li>
                                <li>Share photos or videos of AISC students on social media, to news media or outside groups without explicit permission from AISC.</li>
                                <li>Use, supply, or be in possession of alcohol, tobacco, and other legal/illegal substances such as (but not limited to) drugs, cigarettes, and e-cigarettes.</li>
                                <li>Share or expose students to inappropriate videos, apps, or reading material while participating in student-related activities on or off campus.</li>
                            </ul>
                            <p>
                                Please be aware that our campus is equipped with CCTV surveillance to ensure the safety and security of all individuals present. We expect all visitors to adhere strictly to the established guidelines and expectations outlined.
                            </p>
                            <p>
                                Failure to comply with these expectations may result in immediate removal from the campus and a permanent ban on future entry. We appreciate your cooperation and understanding in maintaining a safe and respectful environment for everyone.
                            </p>
                            <Button className="agreeBtn" onClick={() => handleCardClick()}>I AGREE</Button>
                        </div>
                    </div>
                </div>
            </Box>
        </Layout>
    );
};

export default AttestationForm;
