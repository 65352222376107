import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import CogIcon from "@heroicons/react/24/solid/CogIcon";
import LockClosedIcon from "@heroicons/react/24/solid/LockClosedIcon";
import ShoppingBagIcon from "@heroicons/react/24/solid/ShoppingBagIcon";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import UserPlusIcon from "@heroicons/react/24/solid/UserPlusIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import XCircleIcon from "@heroicons/react/24/solid/XCircleIcon";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import BadgeIcon from "@mui/icons-material/Badge";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { SvgIcon } from "@mui/material";
const userRole = localStorage.getItem('userRole');
const userSection = localStorage.getItem('userSection');
let uRole = JSON.parse(userRole);
let uSection = JSON.parse(userSection);

let item;
if (uRole!=null && uRole.includes('Principal')) {
  item = [
    {
      title: "Visitor Management",
      icon: (
        <SvgIcon fontSize="small">
          <GroupAddIcon />
        </SvgIcon>
      ),
      path: "/visitors",
      children: [
        
        {
          path: "/visitor/-1",
          title: "New Visitor",
          icon: (
            <SvgIcon fontSize="small">
              <GroupAddIcon />
            </SvgIcon>
          ),
        },
      ],
    },
    {
      title: "Reports",
      path: "/reports",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },
  ];
} else if (uRole!=null && uRole.includes('Secretary')) {
  if(uSection == 'MS')
  {
    item = [
    
      /*{
        title: "Visitor Management",
        icon: (
          <SvgIcon fontSize="small">
            <GroupAddIcon />
          </SvgIcon>
        ),
        path: "/visitors",
        children: [
          
          {
            path: "/visitor/-1",
            title: "New Visitor",
            icon: (
              <SvgIcon fontSize="small">
                <GroupAddIcon />
              </SvgIcon>
            ),
          },
        ],
      },*/
      {
        title: "Reports",
        path: "/reportsms",
        icon: (
          <SvgIcon fontSize="small">
            <AssessmentIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Bus Management",
        path: "/bus",
        icon: (
          <SvgIcon fontSize="small">
            <AssessmentIcon />
          </SvgIcon>
        ),
      },
    ];
  }
  else if(uSection == 'ES')
  {
    item = [
    
      /*{
        title: "Visitor Management",
        icon: (
          <SvgIcon fontSize="small">
            <GroupAddIcon />
          </SvgIcon>
        ),
        path: "/visitors",
        children: [
          
          {
            path: "/visitor/-1",
            title: "New Visitor",
            icon: (
              <SvgIcon fontSize="small">
                <GroupAddIcon />
              </SvgIcon>
            ),
          },
        ],
      },*/
      {
        title: "Reports",
        path: "/reportses",
        icon: (
          <SvgIcon fontSize="small">
            <AssessmentIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Bus Management",
        path: "/bus",
        icon: (
          <SvgIcon fontSize="small">
            <AssessmentIcon />
          </SvgIcon>
        ),
      },
    ];
  }
  else if(uSection == 'HS')
  {
    item = [
    
      /*{
        title: "Visitor Management",
        icon: (
          <SvgIcon fontSize="small">
            <GroupAddIcon />
          </SvgIcon>
        ),
        path: "/visitors",
        children: [
          
          {
            path: "/visitor/-1",
            title: "New Visitor",
            icon: (
              <SvgIcon fontSize="small">
                <GroupAddIcon />
              </SvgIcon>
            ),
          },
        ],
      },*/
      {
        title: "Reports",
        path: "/reportshs",
        icon: (
          <SvgIcon fontSize="small">
            <AssessmentIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Bus Management",
        path: "/bus",
        icon: (
          <SvgIcon fontSize="small">
            <AssessmentIcon />
          </SvgIcon>
        ),
      },
    ];
  }
  else
  {
    item = [
    
      /*{
        title: "Visitor Management",
        icon: (
          <SvgIcon fontSize="small">
            <GroupAddIcon />
          </SvgIcon>
        ),
        path: "/visitors",
        children: [
          
          {
            path: "/visitor/-1",
            title: "New Visitor",
            icon: (
              <SvgIcon fontSize="small">
                <GroupAddIcon />
              </SvgIcon>
            ),
          },
        ],
      },*/
      {
        title: "Reports",
        path: "/reports",
        icon: (
          <SvgIcon fontSize="small">
            <AssessmentIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Bus Management",
        path: "/bus",
        icon: (
          <SvgIcon fontSize="small">
            <AssessmentIcon />
          </SvgIcon>
        ),
      },
    ];
  }
  
} else if (uRole!=null && uRole.includes('Staff')) {
  console.log("test2");
  item = [
    {
      title: "Visitor Management",
      icon: (
        <SvgIcon fontSize="small">
          <GroupAddIcon />
        </SvgIcon>
      ),
      path: "/visitors",
      children: [
        
        {
          path: "/visitor/-1",
          title: "New Visitor",
          icon: (
            <SvgIcon fontSize="small">
              <GroupAddIcon />
            </SvgIcon>
          ),
        },
      ],
    },
    /*{
      title: "Reports",
      path: "/reports",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },*/
  ];
} else if (uRole!=null && uRole.includes('Vendor')) {
  console.log("test3");
  item = [
    
    {
      title: "Visitor Management",
      icon: (
        <SvgIcon fontSize="small">
          <GroupAddIcon />
        </SvgIcon>
      ),
      path: "/visitors",
      children: [
        
        {
          path: "/visitor/-1",
          title: "New Visitor",
          icon: (
            <SvgIcon fontSize="small">
              <GroupAddIcon />
            </SvgIcon>
          ),
        },
      ],
    },
    
    /*{
      title: "Reports",
      path: "/reports",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },*/
  ];
} else if (uRole!=null && uRole.includes('Manager')) {
  //console.log("test4");
  item = [
   
    {
      title: "Visitor Management",
      icon: (
        <SvgIcon fontSize="small">
          <GroupAddIcon />
        </SvgIcon>
      ),
      path: "/visitors",
      children: [
        
        {
          path: "/visitor/-1",
          title: "New Visitor",
          icon: (
            <SvgIcon fontSize="small">
              <GroupAddIcon />
            </SvgIcon>
          ),
        },
      ],
    },
    {
      title: "Reports",
      path: "/reports",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },
    {
      title: "Reports Staff",
      path: "/reports-staff",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },
    {
      title: "Bus Management",
      path: "/bus",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },
  ];
} else {
  //console.log("test5");
  item = [
    {
      title: "RFID Check-in",
      icon: (
        <SvgIcon fontSize="small">
          <DocumentScannerIcon />
        </SvgIcon>
      ),
      path: "/manual",
    },
    /*{
      title: "RFID Check-out",
      icon: (
        <SvgIcon fontSize="small">
          <DocumentScannerIcon />
        </SvgIcon>
      ),
      path: "/manualout",
    },*/
    /*{
      title: "QR Check-in",
      path: "/qrcheckin",
      icon: (
        <SvgIcon fontSize="small">
          <QrCodeIcon />
        </SvgIcon>
      ),
    },*/
    /*{
      title: "User Management",
      icon: (
        <SvgIcon fontSize="small">
          <ManageAccountsIcon />
        </SvgIcon>
      ),
    },*/
    {
      title: "Visitor Management",
      icon: (
        <SvgIcon fontSize="small">
          <GroupAddIcon />
        </SvgIcon>
      ),
      path: "/visitors",
      children: [
        {
          path: "/visitors",
          title: "Visitor List",
          icon: (
            <SvgIcon fontSize="small">
              <GroupAddIcon />
            </SvgIcon>
          ),
        },
        {
          path: "/preregister/-1",
          title: "Pre Register Visitor",
          icon: (
            <SvgIcon fontSize="small">
              <GroupAddIcon />
            </SvgIcon>
          ),
        },
        {
          path: "/visitor/-1",
          title: "New Visitor",
          icon: (
            <SvgIcon fontSize="small">
              <GroupAddIcon />
            </SvgIcon>
          ),
        },
      ],
    },
    {
      title: "Users",
      icon: (
        <SvgIcon fontSize="small">
          <UsersIcon />
        </SvgIcon>
      ),
      children: [
        {
          path: "/staffs",
          title: "Users List",
          icon: (
            <SvgIcon fontSize="small">
              <GroupAddIcon />
            </SvgIcon>
          ),
        },
        {
          path: "/staff/-1",
          title: "New User",
          icon: (
            <SvgIcon fontSize="small">
              <GroupAddIcon />
            </SvgIcon>
          ),
        },
      ]
    },
    {
      title: "Gate Pass",
      icon: (
        <SvgIcon fontSize="small">
          <BadgeIcon />
        </SvgIcon>
      ),
    },
    {
      title: "Manage Deliveries",
      icon: (
        <SvgIcon fontSize="small">
          <LocalShippingIcon />
        </SvgIcon>
      ),
    },
    {
      title: "Reports",
      path: "/reports",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },
    {
      title: "Temp ID Management",
      path: "/tempid",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },
    {
      title: "Temp ID Reports",
      path: "/tempidreports",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },
    {
      title: "Reports Staff",
      path: "/reports-staff",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },
    {
      title: "Reports Out",
      path: "/reportsout",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },
    {
      title: "Bus Management",
      path: "/bus",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },
    {
      title: "Decal Requests",
      path: "/decal",
      icon: (
        <SvgIcon fontSize="small">
          <LocalShippingIcon />
        </SvgIcon>
      ),
    },
    {
      title: "Self Attestation",
      path: "/self-attestation",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },
    {
      title: "Images",
      path: "/images",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    },
    {
      title: "Error Logs",
      path: "/errorlogs",
      icon: (
        <SvgIcon fontSize="small">
          <AssessmentIcon />
        </SvgIcon>
      ),
    }
  ];
}
export const items = item;
